@import url("https://fonts.googleapis.com/css?family=Poppins:400,600");

html {
  height: 100%;
}

body {
  min-height: 100%;
}
html,
body {
  position: relative;
  overflow-x: hidden;

  -webkit-user-select: none;
  -moz-user-select: -moz-none;
  -ms-user-select: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  user-select: none;
}

#root {
  min-height: 100vh;
  font-family: "Poppins", sans-serif !important;
  min-width: 250px;
  width: auto !important;
}

input,
textarea {
  -moz-user-select: text;
}

.bp3-popover-wrapper.multi-inline {
  & > .bp3-popover-target {
    display: inline;

    & > div {
      margin-top: 5px;
      min-width: 200px;
    }
  }
}

$pt-intent-primary: #2badfc;
$pt-intent-success: #00c685;
$pt-intent-danger: #ff3438;

@import "~@blueprintjs/core/src/blueprint.scss";
@import "~@blueprintjs/datetime/src/blueprint-datetime.scss";

.bp3-control.bp3-checkbox input:checked ~ .bp3-control-indicator:before {
  background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16'%3E%3Cpath fill-rule='evenodd' clip-rule='evenodd' d='M12 5c-.28 0-.53.11-.71.29L7 9.59l-2.29-2.3a1.003 1.003 0 0 0-1.42 1.42l3 3c.18.18.43.29.71.29s.53-.11.71-.29l5-5A1.003 1.003 0 0 0 12 5z' fill='rgba(255,255,255,1)'/%3E%3C/svg%3E");
}

.StripeElement {
  display: inline-block;
  width: 220px !important;
  margin-top: 3px;
  margin-bottom: 5px;
  padding: 9.5px 15px;
  font-size: 1em;
  box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0),
    inset 0 0 0 1px rgba(16, 22, 26, 0.15),
    inset 0 1px 1px rgba(16, 22, 26, 0.2);
  border: 0;
  outline: 0;
  border-radius: 55px;
  background: white;
}

.InputElement {
  color: #626262 !important;
}

.dragging-helper-class {
  opacity: 0.7;
}

.user-select__scrollable {
  max-height: 350px;
  min-width: 250px;
  overflow-y: auto;
}

input:not([type="checkbox"]) {
  -webkit-appearance: none;
}

.gantt_tooltip {
  white-space: pre-line;
  max-width: 300px;
  border-radius: 10px;
  box-shadow: 5px 5px 20px rgba(0, 0, 0, 0.2);
  padding: 10px;
}

.bp3-overlay-backdrop {
  background-color: #115679 !important;
  opacity: 0.5 !important;
}

[class*="MuiPaper-elevation2"],
.jss5 {
  box-shadow: none !important;
}

.gantt_layout_cell_border_bottom {
  border-bottom: 1px solid transparent !important;
}

.gantt_layout_cell_border_top {
  border-top: 1px solid transparent !important;
}

.gantt_layout_cell_border_right {
  border-right: 1px solid transparent !important;
}

.gantt_layout_cell_border_left {
  border-left: 1px solid transparent !important;
}

.gantt_layout_cell {
  position: relative;
  box-sizing: border-box;
}

.gantt_container {
  font-size: 13px !important;
  border: 1px solid transparent !important;
}

.gantt_grid_scale .gantt_grid_head_cell {
  color: rgba(16, 22, 26, 0.4);
  font-size: 14px;
  font-weight: 600;
  border-top: none !important;
  border-right: none !important;
}

.gantt_grid_data .gantt_row.gantt_selected,
.gantt_grid_data .gantt_row.odd.gantt_selected,
.gantt_task_row.gantt_selected {
  background-color: #ebebeb;
  // border-radius: 20px;
}

.gantt_grid_data .gantt_row.odd:hover,
.gantt_grid_data .gantt_row:hover {
  background-color: rgba(43, 172, 252, 0.5);
}

.gantt_task_row.gantt_selected .gantt_task_cell {
  border-right-color: #ffffff;
}

.gantt_task_cell .gantt_task_scale .gantt_scale_cell {
  color: #a6a6a6;
  font-size: 14px;
  font-weight: 600;
  border-right: 1px solid #ebebeb;
}

.bp3-icon.bp3-intent-primary,
.bp3-icon-standard.bp3-intent-primary,
.bp3-icon-large.bp3-intent-primary {
  color: #1799e8;
}

.bp3-icon.bp3-intent-success,
.bp3-icon-standard.bp3-intent-success,
.bp3-icon-large.bp3-intent-success {
  color: #0DD693;
}

.bp3-multi-select > .bp3-tag-input-values > input {
  min-width: 150px;
}

.bp3-dialog {
  min-width: 250px;
  border-radius: 20px;
  box-shadow: none;
}

.bp3-dialog-small {
  min-width: auto;
  width: auto;
}

.bp3-dialog-header {
  box-shadow: none !important;
  background-color: #ebf1f5;
  border-radius: 20px;

  .bp3-heading {
    color: #2badfc !important;
    font-weight: 700;
    font-family: "Poppins", sans-serif !important;
  }
}

.bp3-button.default-button {
  color: white !important;
  background: #2badfc !important;
  border-radius: 50px;
  box-shadow: none !important;
  font-weight: bold;
  transition: 250ms;
  text-align: center;

  &:focus {
    outline: none;
  }

  &:hover {
    box-shadow: 1px 1px 20px rgba(0, 0, 0, 0.2) !important;
  }
}
